import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/css/45-south-take-2.webflow.css'
import '../styles/css/normalize.css'
import '../styles/css/webflow.css'
import React from 'react';
import Obfuscate from 'react-obfuscate';

function Footer() {
  return (
    <span className="af-view">
      <div>
        <div className="af-class-section_footer af-class-wf-section">
          <div className="w-row">
            <div className="w-col w-col-4">
              <a href="about" className="af-class-link">About us</a>
              <a href="seasonalwork" className="af-class-link">Seasonal Work</a>
              <a href="exports" className="af-class-link">Exports</a>
              <a href="contact" className="af-class-link">Contact Us</a>
              <a href="apply" className="af-class-link">Apply</a>
            </div>
            <div className="w-col w-col-4">
              <h5 className="af-class-heading-8">Physical Address</h5>
              <div className="af-class-text-block-2">Corner Ord Road &amp; State Highway 6</div>
              <div className="af-class-text-block-2">Cromwell 9384, New Zealand</div>
              <h5 className="af-class-heading-9">Postal Address</h5>
              <div className="af-class-text-block-2">PO&nbsp;Box 46</div>
              <div className="af-class-text-block-2">Cromwell 9342, New Zealand</div>
            </div>
            <div className="af-class-column-26 w-col w-col-4">
              <h5 className="af-class-heading-8">Sales Enquiries</h5>
              <div className="af-class-text-block-2">
                <Obfuscate
                  email="richard@45s.co.nz"
                  headers={{
                    subject: 'Website Sales Enquiry',
                  }}
                />
              </div>
              <h5 className="af-class-heading-9">Job Enquiries</h5>
              <div className="af-class-text-block-2">
                <Obfuscate
                  email="employment@45s.co.nz"
                  headers={{
                    subject: 'Website Job Enquiry',
                  }}
                />
              </div>
              <h5 className="af-class-heading-8">Office Phone</h5>
              <div className="af-class-text-block-2">
                <Obfuscate
                  tel="+64 3 445 1402"
                />
              </div>
              <h5 className="af-class-heading-8">Employment Phone</h5>
              <div className="af-class-text-block-2">
                <Obfuscate
                  tel="+64 20 5906 3390"
                />
              </div>
            </div>
          </div>
          <div className="af-class-columns-2 w-row">
            <div className="af-class-column-3 w-col w-col-6">
              <a href="https://www.facebook.com/45SouthCherries/" className="w-inline-block"><img src="images/facebook.svg" loading="lazy" alt="facebook link" className="af-class-facebook_link" /></a>
              <a className="af-class-link" href="https://firebasestorage.googleapis.com/v0/b/fortyfivesouthwebsite.appspot.com/o/Quality%20Policy%202022%20-%20Final.pdf?alt=media&token=d76cd566-0c8f-4374-b9b3-49b49c8c60aa/">Quality Policy</a>
            </div>
            <div className="af-class-column-3 w-col w-col-6">

            </div>
            <div className="af-class-column-42 w-clearfix w-col w-col-6">
              <div className="af-class-text-block-3">© 2022</div><img src="images/45SLogo.png" alt="Logo-black" className="af-class-image-5" />
            </div>
          </div>

        </div>
        <div className="w-embed w-script">
        </div>
        {/* [if lte IE 9]><![endif] */}
        {/*  Google Tag Manager (noscript)  */}
        <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NCJNF4D" height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
        {/*  End Google Tag Manager (noscript)  */}
      </div>

    </span>
  );
}

export default Footer;